import { useWindowSize } from '@uidotdev/usehooks';
import React, { useContext, useEffect } from 'react';
import Card from '../../cards/card/Card';
import DoubleInput from '../doubleInput/DoubleInput';
import Trash from '../../../atoms/icons/general/trash/Trash';
import FilledButton from '../../buttons/filledButton/FilledButton';
import Add from '../../../atoms/icons/general/add/Add';
import Input from '../input/Input';
import './MultiplesInputs.css';
import { removeSpaces } from '../../../../utils/Utils';
import AppContext from '../../../../context/AppContext';
import UnfilledButton from '../../buttons/unfilledButton/UnfilledButton';

// inputType = 1 => DoubleInput / inputType = 2 => 2 Inputs
// Form = {
//   register: register,
//   watch: watch,
//   setValue: setValue,
//   setError: setError,
//   clearErrors: clearErrors,
//   errors: errors,
//   Form.unregister: Form.unregister,
//   getValues: getValues,
//   trigger: trigger,
// };

function MultiplesInputs({
  className = '',
  name,
  Form,
  inputType = 1,
  label,
  visible = true,
  onInputChange = () => {},
  showDeleteModal,
  secondaryParcours,
}) {
  const { setModalVisible, setModalContent } = useContext(AppContext);
  const { width } = useWindowSize();
  const watcher = Form.watch(name);

  // useEffect(() => {
  //   onInputChange();
  // }, [values]);

  useEffect(() => {
    if (visible) {
      if (!watcher) {
        Form.register(name);
        Form.setValue(name, []);
      }
    } else {
      Form.unregister(name);
    }
  }, [visible]);

  function inputChangeCallback() {
    Form.trigger(name);
    onInputChange();
  }

  function getRowInputsName(index) {
    return inputType === 1
      ? [`${name}.${index}.input`, `${name}.${index}.value`, `${name}.${index}.id`]
      : [`${name}.${index}.name`, `${name}.${index}.description`, `${name}.${index}.id`];
  }

  function showDeleteRowModal(index) {
    if (!showDeleteModal) return deleteRow(index);

    setModalContent({
      title: 'Supprimer une ligne',
      content: (
        <>
          Attention, supprimer cette ligne la supprimera également sur les années secondaires (si vous en avez
          renseignées). Voulez-vous continuer ?
          <div className='modal-buttons'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteRow(index)}>
              Supprimer
            </FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  function deleteRow(index) {
    Form.unregister(getRowInputsName(index));

    setModalVisible(false);
    inputChangeCallback();
  }

  function addRow() {
    const lastIndex = Number(Object.keys(Form.getValues(name) ?? {}).at(-1)) + 1 || 0;
    const inputsName = getRowInputsName(lastIndex);

    inputsName.forEach(inputName => {
      Form.register(inputName);
      Form.setValue(inputName, '');
    });

    onInputChange();
  }

  function validateRequiredAndUnique(value, allValues) {
    if (!value) return 'Ce champ est obligatoire';

    const duplicateCount = allValues.filter(v => v === value).length;
    if (duplicateCount > 1) return 'Ce champ doit être unique';

    return true;
  }

  return (
    <div className={`multiple-inputs-container ${className}`}>
      {label ? visible ? <label className='form-label'>{label}</label> : null : null}
      {secondaryParcours && name === 'remunerations' && (
        <p className='label-tip'>
          Pour ajouter une ligne de retraitement de rémunération supplémentaire, rendez-vous sur votre année principale
        </p>
      )}
      {watcher?.map((value, index) => (
        <Card key={index} bgColor='var(--white)' padding='20px 20px 4px'>
          <div className='row'>
            <input type='hidden' className='d-none' {...Form.register(`${name}.${index}.id`)} />
            {inputType === 1 ? (
              <>
                <DoubleInput
                  disabled1={secondaryParcours}
                  inputType={inputType}
                  defaultValue={0}
                  validation1={{
                    validate: value =>
                      validateRequiredAndUnique(
                        value,
                        watcher.map(v => v.input),
                      ),
                  }}
                  name1={`${name}.${index}.input`}
                  name={`${name}.${index}.value`}
                  placeholder1='Intitulé'
                  placeholder2='Montant'
                  onChange={inputChangeCallback}
                  useForm={Form}
                  icon='euro'
                  error1={Form.errors?.[name]?.[index]?.input?.message}
                  error={Form.errors?.[name]?.[index]?.value?.message}
                />
              </>
            ) : (
              <div className='two-inputs-container'>
                <Input
                  name={`${name}.${index}.name`}
                  label='Nom'
                  register={Form.register}
                  error={Form.errors?.[name]?.[index]?.name?.message}
                />
                <Input
                  name={`${name}.${index}.description`}
                  label='Description'
                  register={Form.register}
                  error={Form.errors?.[name]?.[index]?.description?.message}
                  onChange={e => {
                    onInputChange(e);
                  }}
                />
              </div>
            )}
            {!secondaryParcours && (
              <div className='pb-md px-sm centered'>
                <Trash onClick={() => showDeleteRowModal(index)} />
              </div>
            )}
          </div>
        </Card>
      ))}
      {secondaryParcours ? null : visible ? (
        <FilledButton onClick={addRow}>
          <Add width={width > 576 ? '30px' : '20px'} /> Ajouter une ligne
        </FilledButton>
      ) : null}
    </div>
  );
}

export default MultiplesInputs;

export function formatMultipleInputsValues(values, previousValues) {
  previousValues = JSON.parse(previousValues);

  if (!values) return { deleted_inputs: previousValues.map(value => ({ id: value.id })) };

  values = values
    .map(value => {
      if (value.input && value.value) return { input: value.input, id: value.id, value: removeSpaces(value.value) };
    })
    .filter(value => value);

  if (!previousValues) return { added_inputs: values };

  if (!values) return { deleted_inputs: previousValues.map(value => value.id) };

  const result = {
    added_inputs: [],
    deleted_inputs: [],
    updated_inputs: [],
  };

  result.deleted_inputs = previousValues
    .filter(value => !values.find(v => v.id === value.id))
    .map(value => ({ id: value.id }));

  values.forEach(value => {
    const prev = previousValues.find(v => v.id === value.id);

    if (!prev) {
      result.added_inputs.push(value);
    } else if (prev.input !== value.input || Number(prev.value) !== value.value) {
      result.updated_inputs.push(value);
    }
  });

  return result;
}

export function removeSpacesFromMultiplesInputs(values) {
  if (!values) return [];

  return values
    .map(value => {
      if (value.input && value.value) return { input: value.input, value: removeSpaces(value.value) };
    })
    .filter(value => value);
}

export function calcTotalFormMultipleInputs(values) {
  return values?.reduce((acc, value) => acc + removeSpaces(value.value), 0) ?? 0;
}

export function fillMultipleInputs(values, name, setValue, type = 1) {
  setValue(name, null);
  values?.forEach((value, index) => {
    if (type === 1) {
      setValue(`${name}.${index}.input`, value.input);
      setValue(`${name}.${index}.value`, value.value);
      setValue(`${name}.${index}.id`, value.id);
    } else {
      setValue(`${name}.${index}.name`, value.name);
      setValue(`${name}.${index}.description`, value.description);
      setValue(`${name}.${index}.id`, value.id);
    }
  });
}
