import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatObjectForPosting, getTotalFromValues, removeSpaces } from '../../../../../utils/Utils';
import { ParcoursContext } from '../../../../../context/ParcoursContext';
import NumberInput from '../../../../molecules/formComponents/numberInput/NumberInput';
import Textarea from '../../../../molecules/formComponents/textarea/Textarea';
import { numberValidation } from '../../../../../utils/formValidation/FormValidation';
import MultiplesInputs, {
  calcTotalFormMultipleInputs,
  fillMultipleInputs,
  formatMultipleInputsValues,
} from '../../../../molecules/formComponents/multiplesInputs/MultiplesInputs';
import Tuto from '../../../../atoms/icons/general/tuto/Tuto';

function BilanPassif({ id, index, secondaryParcours }) {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    watch,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();
  const Form = { watch, register, setValue };
  const {
    moveToNextStep,
    getStepToGo,
    setGoToStep,
    step,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
    showTooltip,
  } = useContext(ParcoursContext);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(
      'bilan_passif',
      [
        'capitaux_propres',
        'provisions_risques_charges',
        'decouverts',
        'comptes_courants_associes',
        'dettes_fournisseurs',
        'autres_dettes',
        'total_dettes',
        'total_passif',
        'dettes_etablissements_credit',
        'produits_constates_avance',
      ],
      setValue,
      estimationValues,
    );
    fillInputsFromEstimationValues(
      'dettes_hors_exploitation',
      ['banque_court_terme', 'banque_moyen_terme', 'total_dettes_hors_exploitation', 'note_dettes_hors_exploitation'],
      setValue,
      estimationValues,
    );
    setValue('valeur_materiel_exploitation', estimationValues?.materiel_agencements?.valeur_materiel_exploitation);
    setValue('comptes_courants_associes_bis', estimationValues?.dettes_hors_exploitation?.comptes_courants_associes);

    fillMultipleInputs(estimationValues?.dettes_hors_exploitation?.autres, 'autres', setValue);
    setValue('tmp.previous_dettes', JSON.stringify(estimationValues?.dettes_hors_exploitation?.autres));
  }, [estimationValues]);

  async function formSubmit(values) {
    const tmp = {
      bilan_passif: formatObjectForPosting({
        capitaux_propres: values.capitaux_propres,
        provisions_risques_charges: values.provisions_risques_charges,
        decouverts: values.decouverts,
        comptes_courants_associes: values.comptes_courants_associes,
        dettes_fournisseurs: values.dettes_fournisseurs,
        autres_dettes: values.autres_dettes,
        total_dettes: values.total_dettes,
        total_passif: values.total_passif,
        dettes_etablissements_credit: values.dettes_etablissements_credit,
        produits_constates_avance: values.produits_constates_avance,
      }),
      dettes_hors_exploitation: formatObjectForPosting({
        banque_court_terme: values.banque_court_terme,
        banque_moyen_terme: values.banque_moyen_terme,
        comptes_courants_associes: values.comptes_courants_associes_bis,
        total_dettes_hors_exploitation: values.total_dettes_hors_exploitation,
      }),
      materiel_agencements: {
        valeur_materiel_exploitation: removeSpaces(values.valeur_materiel_exploitation),
      },
    };

    tmp.dettes_hors_exploitation.autres = formatMultipleInputsValues(values.autres, values.tmp.previous_dettes);

    if (!secondaryParcours)
      tmp.dettes_hors_exploitation.note_dettes_hors_exploitation = values.note_dettes_hors_exploitation;

    postValues(tmp);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  //calcul auto des inputs
  function calcTotalDettes() {
    const tmp = getValues([
      'comptes_courants_associes',
      'dettes_fournisseurs',
      'autres_dettes',
      'decouverts',
      'dettes_etablissements_credit',
      'produits_constates_avance',
    ]);
    setValue('total_dettes', getTotalFromValues(tmp));

    calcTotalPassif();
  }

  function calcTotalPassif() {
    const tmp = getValues(['total_dettes', 'capitaux_propres', 'provisions_risques_charges']);
    setValue('total_passif', getTotalFromValues(tmp));
  }

  function calcTotalDetteshorsExploitation() {
    const tmp = getValues(['banque_court_terme', 'banque_moyen_terme', 'comptes_courants_associes']);
    const autres = getValues('autres');

    setValue(
      'total_dettes_hors_exploitation',
      removeSpaces(getTotalFromValues(tmp)) + calcTotalFormMultipleInputs(autres),
    );

    calcTotalPassif();
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? 'auto' : '0' }}>
      <input type='hidden' {...register('tmp.previous_dettes')} />
      <input type='hidden' {...register('tmp.errors_dettes')} />
      <p className='text-blue mb-3 text-xs'>Veuillez renseigner tous les champs (même si la somme est "0")</p>
      <fieldset className='borderless-fieldset'>
        <h2>Bilan passif</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            label={
              <>
                Capitaux propres <Tuto onClick={() => showTooltip('step_5_tuto_1.webp')} />
              </>
            }
            name='capitaux_propres'
            error={errors?.capitaux_propres && errors.capitaux_propres.message}
            onChange={calcTotalPassif}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalPassif}
            label={
              <>
                Provisions pour risques et charges <Tuto onClick={() => showTooltip('step_5_tuto_2.webp')} />
              </>
            }
            name='provisions_risques_charges'
            error={errors?.provisions_risques_charges && errors.provisions_risques_charges.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={e => {
              setValue('banque_moyen_terme', e.target.value);
              calcTotalDettes();
              calcTotalDetteshorsExploitation();
            }}
            label={
              <>
                Dettes auprès d'établissements de crédit <Tuto onClick={() => showTooltip('step_5_tuto_3.webp')} />
              </>
            }
            name='dettes_etablissements_credit'
            error={errors?.dettes_etablissements_credit && errors.dettes_etablissements_credit.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            label={
              <>
                Découvert bancaire <Tuto onClick={() => showTooltip('step_5_tuto_4.webp')} />
              </>
            }
            onChange={e => {
              setValue('banque_court_terme', e.target.value);
              calcTotalDettes();
              calcTotalDetteshorsExploitation();
            }}
            name='decouverts'
            error={errors?.decouverts && errors.decouverts.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={e => {
              setValue('comptes_courants_associes_bis', e.target.value);
              calcTotalDettes();
              calcTotalDetteshorsExploitation();
            }}
            label={
              <>
                Comptes courants associés <Tuto onClick={() => showTooltip('step_5_tuto_5.webp')} />
              </>
            }
            name='comptes_courants_associes'
            error={errors?.comptes_courants_associes && errors.comptes_courants_associes.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={calcTotalDettes}
            label={
              <>
                Dettes fourniseurs <Tuto onClick={() => showTooltip('step_5_tuto_6.webp')} />
              </>
            }
            name='dettes_fournisseurs'
            error={errors?.dettes_fournisseurs && errors.dettes_fournisseurs.message}
            icon='euro'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={Form}
            onChange={calcTotalDettes}
            label={
              <>
                Autres dettes <Tuto onClick={() => showTooltip('step_5_tuto_7.webp')} />
              </>
            }
            name='autres_dettes'
            error={errors?.autres_dettes && errors.autres_dettes.message}
            icon='euro'
          />
          <NumberInput
            useForm={Form}
            onChange={e => {
              setValue('produits_constates_avance', e.target.value);
              calcTotalDettes();
              calcTotalDetteshorsExploitation();
            }}
            label={
              <>
                Produits constatés d'avance <Tuto onClick={() => showTooltip('step_5_tuto_8.webp')} />
              </>
            }
            name='produits_constates_avance'
            error={errors?.produits_constates_avance && errors.produits_constates_avance.message}
            icon='euro'
          />
        </div>
        <NumberInput
          bgColor='var(--main-color)'
          disabled
          useForm={Form}
          validation={{}}
          label='total des dettes'
          name='total_dettes'
          error={errors?.total_dettes && errors.total_dettes.message}
          icon='euro'
        />
        <NumberInput
          disabled
          validation={{}}
          bgColor='var(--main-color)'
          useForm={Form}
          label='total du passif'
          name='total_passif'
          error={errors?.total_passif && errors.total_passif.message}
          icon='euro'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>Détail des dettes hors exploitation</h2>
        {secondaryParcours && (
          <p className='label-tip ml-sm'>
            Pour ajouter une ligne de dette supplémentaire, rendez-vous sur votre année principale
          </p>
        )}
        <div className='row-1000'>
          <NumberInput
            onChange={calcTotalDetteshorsExploitation}
            useForm={Form}
            label='banque moyen terme (prêts...)'
            name='banque_moyen_terme'
            error={errors?.banque_moyen_terme && errors.banque_moyen_terme.message}
            icon='euro'
            disabled
          />
          <NumberInput
            onChange={calcTotalDetteshorsExploitation}
            useForm={Form}
            label='banque court terme (découvert)'
            name='banque_court_terme'
            error={errors?.banque_court_terme && errors.banque_court_terme.message}
            icon='euro'
            disabled
          />
        </div>
        <NumberInput
          onChange={calcTotalDetteshorsExploitation}
          useForm={Form}
          label='Comptes courant associés'
          name='comptes_courants_associes_bis'
          error={errors?.comptes_courants_associes_bis && errors.comptes_courants_associes_bis.message}
          validation={numberValidation}
          icon='euro'
          disabled
        />
        <MultiplesInputs
          showDeleteModal
          label='Autres :'
          secondaryParcours={secondaryParcours}
          onInputChange={calcTotalDetteshorsExploitation}
          name='autres'
          Form={{ ...Form, setError, clearErrors, errors, getValues, unregister }}
        />
        <NumberInput
          disabled
          validation={{}}
          bgColor='var(--main-color)'
          useForm={Form}
          label='total des dettes hors exploitation'
          name='total_dettes_hors_exploitation'
          error={errors?.total_dettes_hors_exploitation && errors.total_dettes_hors_exploitation.message}
          icon='euro'
        />
        {!secondaryParcours && (
          <Textarea
            register={register}
            error={errors?.note_dettes_hors_exploitation && errors.note_dettes_hors_exploitation.message}
            name='note_dettes_hors_exploitation'
            label='notes et remarques'
            className='mt-md'
          />
        )}
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>
          Matériel d'exploitation <Tuto onClick={() => showTooltip('step_5_tuto_9.webp')} />
        </h2>
        <NumberInput
          useForm={Form}
          label={<>Valeur de marché</>}
          name='valeur_materiel_exploitation'
          error={errors?.valeur_materiel_exploitation && errors.valeur_materiel_exploitation.message}
          validation={numberValidation}
          icon='euro'
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
      </fieldset>
    </form>
  );
}

export default BilanPassif;
