import TwistedArrow from '../../atoms/icons/pdf/twistedArrow/TwistedArrow';
import EstimationsRangePdf from '../estimationsRangePdf/EstimationsRangePdf';
import HrPdf from '../hrPdf/HrPdf';
import './EstimationDetailsPdf.css';
import React, { Fragment } from 'react';

function EstimationDetailsPdf({ data, type, className = '' }) {
  function extractData(detail) {
    return {
      hasTopArrows: false,
      values: [
        {
          estimation: Math.max(0, detail?.low_amount),
          description: (
            <>
              <TwistedArrow width='15px' />
              BASSE
            </>
          ),
        },
        { estimation: Math.max(0, detail?.mid_amount), description: <>MOYENNE</> },
        {
          estimation: Math.max(0, detail?.high_amount),
          description: (
            <>
              HAUTE
              <TwistedArrow color='var(--green)' rotation='180deg' width='15px' />
            </>
          ),
        },
      ],
    };
  }

  const detailsArray = Array.isArray(data) ? data : [data].filter(detail => detail);

  return (
    <div className={`estimation-details-container-pdf ${className}`}>
      {detailsArray.map((detail, index) => (
        <Fragment key={index}>
          <div>
            <p className='estimation-details-title'>{detail.title}</p>
            <p className='estimation-details-subtitle'>{detail.subtitle}</p>
            <EstimationsRangePdf data={extractData(detail)} type={type} className='value-estimation-details' />
          </div>
          {index < detailsArray.length - 1 && <HrPdf className='my-md' />}
        </Fragment>
      ))}
    </div>
  );
}

export default EstimationDetailsPdf;
